import React, { Component } from 'react';
import logo from '../images/FirstVerticalWhite.png';

class LogoLinkWhite extends Component {
    render() {
        return (
            <img src={logo} className="logo" alt="First Vertical"/>
        );
    }
}

export default LogoLinkWhite;