import React from 'react';
import { Route } from 'react-router-dom'
import './App.css';
import Home from './containers/Home'
import ContactUs from './containers/Company/ContactUs'
import AboutUs from './containers/Company/AboutUs'
import PrivacyPolicy from './containers/Company/PrivacyPolicy'
import Header from './components/Header.js';
import JavaSolutionStacks from "./containers/Solutions/JavaSolutionStacks";
import NodeJSSolutionStacks from "./containers/Solutions/NodeJSSolutionStacks";
import ReactSolutionStacks from "./containers/Solutions/ReactSolutionStacks";
import iOSSolutionStacks from "./containers/Solutions/iOSSolutionStacks";
import AndroidSolutionStacks from "./containers/Solutions/AndroidSolutionStacks";
import SquadCast from "./containers/Products/SquadCast";
import WebApplicationEngineering from "./containers/Services/WebApplicationEngineering";
import MobileApplicationEngineering from "./containers/Services/MobileApplicationEngineering";
import APIEngineering from "./containers/Services/APIEngineering";
import WebMarketingDesign from "./containers/Services/WebMarketingDesign";
import Consulting from "./containers/Services/Consulting";
import Training from "./containers/Services/Training";
import Blog from "./containers/Resources/Blog";
import Support from "./containers/Resources/Support";
import DeveloperTools from "./containers/Resources/DeveloperTools";
import Documentation from "./containers/Resources/Documentation";
import Footer from "./components/Footer.js";
const App = () => (

    <div className="App">
        <Header/>
        <Route path="/" exact component={Home} />
        <Route path="/java-solution-stacks" component={JavaSolutionStacks} />
        <Route path="/node-js-solution-stacks" component={NodeJSSolutionStacks} />
        <Route path="/react-solution-stacks" component={ReactSolutionStacks} />
        <Route path="/ios-solution-stacks" component={iOSSolutionStacks} />
        <Route path="/android-solution-stacks" component={AndroidSolutionStacks} />
        <Route path="/squad-cast" component={SquadCast} />
        <Route path="/web-application-engineering" component={WebApplicationEngineering} />
        <Route path="/mobile-application-engineering" component={MobileApplicationEngineering} />
        <Route path="/api-engineering" component={APIEngineering} />
        <Route path="/web-marketing-design" component={WebMarketingDesign} />
        <Route path="/consulting" component={Consulting} />
        <Route path="/training" component={Training} />
        <Route path="/blog" component={Blog} />
        <Route path="/support" component={Support} />
        <Route path="/devtools" component={DeveloperTools} />
        <Route path="/documentation" component={Documentation} />
        <Route path="/contact-us" component={ContactUs} />
        <Route path="/about-us" component={AboutUs} />
        <Route path="/privacy-policy" component={PrivacyPolicy} />
        <Footer/>
    </div>
)


export default App;
