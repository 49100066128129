import React, { Component } from 'react';
import LogoLinkWhite from './LogoLinkWhite.js';
import {Container} from "reactstrap";

class Footer extends Component {
    render() {
        return (
<div className="footer">
                <Container>
                    <a href="/"><LogoLinkWhite/></a>
                </Container>
</div>
        );
    }
}

export default Footer;