import React, { Component } from 'react';
import logo from '../images/FirstVertical.png';

class LogoLink extends Component {
  render() {
    return (
      <img src={logo} className="logo" alt="First Vertical"/>
    );
  }
}

export default LogoLink;