import React from 'react'
import {Container} from "reactstrap";

export default class AboutUs extends React.Component {
    render() {
        return (
            <div className="AboutUs">
                <br/>
                <Container>
                    We are something. Hey there.
                </Container>
            </div>
        );
    }
}
