import React from 'react'
import {Container} from "reactstrap";

export default class DeveloperTools extends React.Component {
    render() {
        return (
            <div className="DeveloperTools">
                <br/>
                <Container>

                </Container>
            </div>
        );
    }
}
