import React from 'react'
import {Container} from "reactstrap";
import { Form, Button, Row, Col } from "react-bootstrap";
import { RingLoader } from 'react-spinners';
import { css } from '@emotion/core';
import ReCAPTCHA from "react-google-recaptcha";

const override = css`
    margin: auto;
    margin-top: 15%;
    position: absolute;
    z-index: 999;
`;

export default class ContactUs extends React.Component {

    state = {
        validated: false,
        name: null,
        email: null,
        phone: null,
        desc: null,
        submitted: false,
        loading: false,
        captcha: false
    };

    _reCaptchaRef = React.createRef();

    handleChange = e => {
        this.setState({ [e.target.name]: e.target.value });
    };
    handleSubmit = (event) => {
        const form = event.currentTarget;
        event.preventDefault();
        event.stopPropagation();
        if (form.checkValidity() === true) {
            this.setState({loading: true});
            fetch("https://bxgij96b98.execute-api.us-west-2.amazonaws.com/default/contact-us-email", {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    name: this.state.name,
                    desc: this.state.desc,
                    phone: this.state.phone,
                    email: this.state.email
                })
            }).then(() => {
                this.setState({submitted: true});
            })
        }
        this.setState({ validated: true });
        console.log("validated");
    };

    onChange = (value) => {
        console.log("Captcha value:", value);
        this.setState({captcha: true});
    };

    render() {
        const { validated } = this.state;
        return (
            <div className="ContactUs">
                <br/>
                <Container>
                    <h4 className="header"> Contact Us </h4>
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                    </p>
                    {!this.state.submitted ?
                        <div className="form">
                            { this.state.loading &&
                                <div>
                                    <div className='loading'>
                                        <RingLoader css={override}
                                                    sizeUnit={"px"}
                                                    size={50}
                                                    color={'#C77A3F'}
                                                    loading={this.state.loading}
                                        />
                                    </div>
                                    <div className="overlay"/>
                                </div>
                            }
                            <Form
                                className="form"
                                noValidate
                                validated={validated}
                                onSubmit={e => this.handleSubmit(e)} >
                                <Form.Group as={Row} controlId="name">
                                    <Form.Label column sm={2}>
                                        Name
                                    </Form.Label>
                                    <Col sm={10}>
                                        <Form.Control name="name" onChange={this.handleChange} required type="text" />
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row} controlId="email">
                                    <Form.Label column sm={2}>
                                        Email
                                    </Form.Label>
                                    <Col sm={10}>
                                        <Form.Control name="email" onChange={this.handleChange} required type="email" />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} controlId="phone">
                                    <Form.Label column sm={2}>
                                        Phone
                                    </Form.Label>
                                    <Col sm={10}>
                                        <Form.Control name="phone" onChange={this.handleChange} type="tel"/>
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row} controlId="desc">
                                    <Form.Label column sm={2}>
                                        Project Description
                                    </Form.Label>
                                    <Col sm={10}>
                                        <Form.Control name="desc" onChange={this.handleChange} required as="textarea" rows="5" />
                                    </Col>
                                </Form.Group>
                                <div className="row">
                                    <div className="form-label col-form-label col-sm-2"/>
                                    <ReCAPTCHA className="col-sm-10"
                                        sitekey="6LcISZYUAAAAAMP5eHl0TSljDV7YZXTm6t3fNkrA"
                                        onChange={this.onChange}
                                        ref={this._reCaptchaRef}>
                                    </ReCAPTCHA>
                                </div>
                                <Button className="float-right" variant="primary" type="submit" disabled={!this.state.captcha}>
                                    Submit
                                </Button>
                                <br/>
                                <br className="clearfix"/>
                            </Form>
                        </div>:
                        <p>
                            Thanks for submitting your request!
                        </p>}
                </Container>
            </div>
        );
    }
}
