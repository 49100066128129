import React from 'react'

export default class Tile extends React.Component {

    trim = string => {
        if(string.length > 200){
            string = string.substring(0,80);
            string = string.substring(0, string.lastIndexOf(' '));
            return string.concat("...");
        }
        else
            return string;

    };
    render() {
        return (
            <a href={this.props.link} className="card">
                <div className="background" style={{backgroundImage: `url(${this.props.image})`}}>
                    <div className="display-over">
                        <div className="hover">
                            <p className="hover-text">
                                {this.trim(this.props.description)}
                            </p>
                        </div>
                    </div>
                </div>
                <div className={"title-box color-" + this.props.color}>
                    <p className="date">{this.props.date}</p>
                    <h4 className="title">{this.props.title}</h4>
                </div>
            </a>
        );
    }
}
