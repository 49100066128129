import React from 'react'
import Tile from "../../components/Tile";
import Container from "react-bootstrap/Container";

export default class Blog extends React.Component {

    render() {
        return (
            <div className="Blog">
                <Container>
                    <Tile/>
                </Container>
            </div>
        );
    }
}
