import React, { Component } from 'react';
import LogoLink from './LogoLink.js';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import {Container} from "reactstrap";

class Header extends Component {
  render() {
    return (
            <Navbar bg="light" expand="lg">
                <Container>
                    <Navbar.Brand href="/"><LogoLink/></Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="ml-auto"  >
                            <NavDropdown title="Solutions" id="basic-nav-dropdown">
                                <NavDropdown.Item href="/java-solution-stacks">Java Solution Stacks</NavDropdown.Item>
                                <NavDropdown.Item href="/node-js-solution-stacks">Node.js Solution Stacks</NavDropdown.Item>
                                <NavDropdown.Item href="/react-solution-stacks">React Solution Stacks</NavDropdown.Item>
                                <NavDropdown.Item href="/ios-solution-stacks">iOS Solution Stacks</NavDropdown.Item>
                                <NavDropdown.Item href="/android-solution-stacks">Android Solution Stacks</NavDropdown.Item>
                            </NavDropdown>
                            <NavDropdown title="Products" id="basic-nav-dropdown">
                                <NavDropdown.Item href="/squad-cast">SquadCast</NavDropdown.Item>
                            </NavDropdown>
                            <NavDropdown title="Services" id="basic-nav-dropdown">
                                <NavDropdown.Item href="/web-application-engineering">Web Application Engineering</NavDropdown.Item>
                                <NavDropdown.Item href="/mobile-application-engineering">Mobile Application Engineering</NavDropdown.Item>
                                <NavDropdown.Item href="/api-engineering">API Engineering</NavDropdown.Item>
                                <NavDropdown.Item href="/web-marketing-design">Web Marketing Design</NavDropdown.Item>
                                <NavDropdown.Item href="/consulting">Consulting</NavDropdown.Item>
                                <NavDropdown.Item href="/training">Training</NavDropdown.Item>
                            </NavDropdown>
                            <NavDropdown title="Resources" id="basic-nav-dropdown">
                                <NavDropdown.Item href="/blog">Blog</NavDropdown.Item>
                                <NavDropdown.Item href="/support">Support</NavDropdown.Item>
                                <NavDropdown.Item href="/devtools">Developer Tools</NavDropdown.Item>
                                <NavDropdown.Item href="/documentation">Documentation</NavDropdown.Item>
                            </NavDropdown>
                            <NavDropdown title="Company" id="basic-nav-dropdown">
                                <NavDropdown.Item href="/about-us">About First Vertical</NavDropdown.Item>
                                <NavDropdown.Item href="/contact-us">Contact Us</NavDropdown.Item>
                                <NavDropdown.Item href="/privacy-policy">Privacy Policy</NavDropdown.Item>
                            </NavDropdown>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
    );
  }
}

export default Header;